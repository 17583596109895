<template>
  <div class="container py-5 d-flex flex-column justify-content-center gap-3">
    <div class="d-flex justify-content-between">
      <div>
        Showing
        <select v-model="perPage">
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
        </select>
        Entries
      </div>
      <form @submit.prevent="search_prod" class="ms-auto">
        <div class="d-flex gap-3">
            <input type="text" v-model="search" placeholder="Product">
            <button type="submit">Search</button>
        </div>
      </form>
    </div>
    <div id="products" class="d-flex flex-wrap gap-3">
      <div class="card" style="width:18rem; max-height: 35rem;" v-for="product in products[table_option.currentPage]" :key="product">
        <img class="card-img-top" :src="product.image" alt="Card image" height="250" width="200">
        <div class="card-body">
          <h4 class="card-title overflow-hidden"
          style="text-overflow: ellipsis; display: -webkit-box !important;
          -webkit-line-clamp: 2; -webkit-box-orient: vertical;
          white-space: normal;">{{product.title}}</h4>
          <p class="card-text overflow-hidden"
          style="text-overflow: ellipsis; display: -webkit-box !important;
          -webkit-line-clamp: 2; -webkit-box-orient: vertical;
          white-space: normal;">{{product.description}}</p>
          <span class="text-muted fw-bold">₱ {{product.price}}</span>
        </div>
        <div class="p-2 ms-auto">
          <router-link :to="{ name: 'Product', params: { name: product.link } }" class="btn btn-primary">See Product</router-link>
        </div>
      </div>
    </div>

    <Pagination :totalPages="table_option.totalPages" :perPage="perPage" :currentPage="table_option.currentPage" :maxVisibleButtons="4" @pagechanged="onPageChange" />
  </div>
</template>

<script>
import Pagination from '../components/Pagination.vue'
export default {
  name: 'Products',
  components: { Pagination },
  data() {
    return {
      products: {},
      search: '',
      table_option: {
        perPage: 10,
        currentPage: 1,
        totalPages: 1
      }
    }
  },
  async mounted() {
    var first = {}
    var final = {}
    if(!Object.values(this.$store.state.products).length) {
      await this.$store.dispatch('getProducts').then(() => {
        Object.keys(this.$store.state.products).some(key => {
          if(Object.values(first).length == this.table_option.perPage) {
            final[Object.values(final).length+1] = first
            first = {}
          }
          first[key] = this.$store.state.products[key]
        })
        final[Object.values(final).length+1] = first
        Object.assign(this.products, final)
        this.table_option.totalPages = Object.values(this.products).length
      })
    }
    else {
      Object.keys(this.$store.state.products).some(key => {
        if(Object.values(first).length == this.table_option.perPage) {
          final[Object.values(final).length+1] = first
          first = {}
        }
        first[key] = this.$store.state.products[key]
      })
      final[Object.values(final).length+1] = first
      Object.assign(this.products, final)
      this.table_option.totalPages = Object.values(this.products).length
    }
    
  },
  methods: {
    search_prod() {
      var first = {}
      var final = {}
      Object.keys(this.$store.state.products).some(key => {
        if(Object.values(first).length == this.table_option.perPage) {
          final[Object.values(final).length+1] = first
          first = {}
        }
        if(this.$store.state.products[key].title.toLowerCase().includes(this.search)) first[key] = this.$store.state.products[key]
      })

      final[Object.values(final).length+1] = first
      Object.assign(this.products, final)
      this.table_option.totalPages = Object.values(final).length
    },
    onPageChange(page) {
      this.table_option.currentPage = page;
      // this.$store.dispatch('logistic/getLogistics', this.table_option);
    },
  },
  computed: {
    sort_by: {
      get() {
        return this.table_option.sort_by ?  this.table_option.sort_by : this.$store.state.tablecontroller.sort_by;
      }
    },
    sort_direction: {
      get() {
        return this.table_option.sort_dir ?  this.table_option.sort_dir : this.$store.state.tablecontroller.sort_dir;
      },
      set(value) {
        this.table_option.sort_dir = value
        // this.$store.dispatch('logistic/getLogistics', this.table_option);
      },
    },
    perPage: {
      get() {
        return this.table_option.perPage ?  parseInt(this.table_option.perPage) : this.$store.state.tablecontroller.perPage;
      },
      set(value) {
        this.table_option.perPage = value
        var first = {}
        var final = {}
        Object.keys(this.$store.state.products).some(key => {
          if(Object.values(first).length == this.table_option.perPage) {
            final[Object.values(final).length+1] = first
            first = {}
          }
          if(this.$store.state.products[key].title.toLowerCase().includes(this.search)) first[key] = this.$store.state.products[key]
        })

        final[Object.values(final).length+1] = first
        Object.assign(this.products, final)
        this.table_option.totalPages = Object.values(final).length
        this.table_option.currentPage = 1
      }
    },
    totalPages: {
        get() {
            return this.$store.state.totalPages
        }
    },
    currentPage: {
        get() {
            return this.$store.state.currentPage
        }
    },
  },
}

</script>